import { Component, OnInit } from '@angular/core';
import { SharingService } from 'src/app/services/sharing.service';
import { ApserviceService } from 'src/app/services/apservice.service';
import { NotifierService } from 'angular-notifier';
import { FileUploader } from 'ng2-file-upload';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-brand-services',
  templateUrl: './brand-services.component.html',
  styleUrls: ['./brand-services.component.scss']
})
export class BrandServicesComponent implements OnInit {

  faClose = faTimes;

  isEditService = false;
  
  // FileUpload Service Variables
  uploader:FileUploader;
  isuploadingProgress;
  uploadStatusProgress;
  saveWithImage:boolean = false;
  filename;
  hasBaseDropZoneOver:boolean;
  hasAnotherDropZoneOver:boolean;

  editorConfig: AngularEditorConfig = {
    editable: true,
      spellcheck: true,
      height: 'auto',
      minHeight: '200',
      maxHeight: 'auto',
      width: 'auto',
      minWidth: '0',
      translate: 'yes',
      enableToolbar: true,
      showToolbar: true,
      placeholder: 'Introduce una descripción...',
      defaultParagraphSeparator: '',
      defaultFontName: '',
      defaultFontSize: '',
      fonts: [
        {class: 'arial', name: 'Arial'},
        {class: 'times-new-roman', name: 'Times New Roman'},
        {class: 'calibri', name: 'Calibri'},
        {class: 'comic-sans-ms', name: 'Comic Sans MS'}
      ],
      customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ],
    uploadUrl: 'v1/image',
    uploadWithCredentials: false,
    sanitize: true,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [
      [
        'strikeThrough',
        'subscript',
        'superscript',
        'indent',
        'outdent',
        'insertUnorderedList',
        'insertOrderedList',
        'heading',
        'fontName'
      ],
      [
        'fontSize',
        'textColor',
        'backgroundColor',
        'customClasses',
        'insertImage',
        'insertVideo',
        'insertHorizontalRule',
        'removeFormat',
        'toggleEditorMode'
      ]
    ]
};

  constructor(
    public _ss: SharingService,
    private _ap: ApserviceService,
    private _ns:NotifierService
  ) { 
    this._ss.setTitle('Servicios');
    this._ss.setHeaderVisible(true);
    this._ss.titleSection = 'Servicios';
    this._ss.saveButton = false;
    this._ss.saveExitButton = false;
    this._ss.printButton = false;
    this._ss.saveNewButton = false;
    this._ss.newButton = false;
    this._ss.backButton = false;
    this.uploader = new FileUploader({
      url: this._ss.adminURL + 'upload',
      method: "POST",
      queueLimit: 1,
      allowedMimeType: ['image/png', 'image/jpeg'],
      itemAlias: 'photo',
    });
  }

  service = {
    id:null,
    title: null,
    description: null,
    description_o: null,
    status:false,
    id_brand: null
  };
  services = [];
  brandservices = [];
  ngOnInit(): void {
    this.getServices();
  }

  getBrandServices(){
    this._ap.getBrandServices().subscribe(response => {
      if(response.result == 'OK'){
        console.log(response.msg);
        console.log(response);
        this.brandservices = response.data;
        this.brandservices.forEach(service => {
          if(service.status == 1){
            this.services.find(x => x.id_service == service.id_service).status = true;
          }
          this.services.find(x => x.id_service == service.id_service).id = service.id;
        });
      }else{
        console.log('Se ha producido un error');
        this._ns.notify('error', 'Error: Se ha producido un error al cargar los servicios!');
      }
    },(err)=>{

    })
  }

  getServices(){
    this._ap.getServices().subscribe(response => {
      if(response.result == 'OK'){
        console.log(response.msg);
        console.log(response);
        this.services = response.data;
        this.services.forEach(service => {
          service.id_service = service.id,
          service.id = null,
          service.description_o = service.description,
          service.image = this._ss.imgservicesURL + service.image,
          service.status = false,
          service.id_brand = this._ss.getBrandSettings().id
        });
        this.getBrandServices();
      }else{
        console.log('Se ha producido un error');
        this._ns.notify('error', 'Error: Se ha producido un error al cargar los servicios!');
      }
    },(err)=>{
      console.log(err);
    })
  }

  getServiceDetails(service){
    console.log(service);
    this.service = service;
    console.log(this.services);
    if(service.id){
      this.service.description = this.brandservices.find(x => x.id_service == service.id_service).description;
    }
    this.isEditService = true;
  }

  changeServiceStatus(service){
    console.log(service);
    this._ap.setServiceStatus(service).subscribe((response)=>{
      if(response.result == 'OK'){
        console.log(response.msg);
        service.status = !service.status;
        if(response.data){
          service.id = response.data;
          this.services.find(x => x.id_service == service.id_service).id = response.data;
          this.brandservices.push(this.services.find(x => x.id == response.data));
        }
        this._ns.notify('success','Servicio activado/desactivado correctamente!');
      }else{
        this._ns.notify('success','Ops, error en!');
      }
    },(err)=>{
      console.log(err);
      this._ns.notify('success','Error: '+err);
    });
  }


  save(){
    this._ap.setBrandService(this.service).subscribe((response)=>{
      if(response.result == 'OK'){
        console.log(response.msg);
        if(response.data){
          this.service.id = response.data;
          this.brandservices.push(this.service);
        }
        this._ns.notify('success','Servicio guardado correctamente!');
      }else{
        this._ns.notify('success','Ops, error en el guardado!');
      }
      this.isEditService = false;
    },(err)=>{
      console.log(err);
      this._ns.notify('success','Error: '+err);

    });
  }
}
