<div class="bg-image"></div>
<div class="content">
    <div class="wrap">
  <div class="form-login">
    <div class="logo-w">
      <img src="../../../assets/images/logo.svg" alt="">
    </div>
    <form class="login-wrapper card">
        <div class="form-row">
          <div class="form-group col-md-12">
            <label for="inputEmail4">Email</label>
            <input type="email" class="form-control" name="email" id="inputEmail4" placeholder="Email" [(ngModel)]="email">
          </div>
          <div class="form-group col-md-12">
            <label for="inputPassword4">Contraseña</label>
            <input type="password" class="form-control" name="password" id="inputPassword4" placeholder="Password"
            [(ngModel)]="password">
          </div>
        </div>
        <div class="button-w">
          <button type="submit" class="btn btn-primary" (click)="login()">Entrar</button>
        </div>  
        <div class="forgot">
          <a class="text-muted" (click)="recoverPass()">¿Has olvidado la contraseña?</a>
        </div>
    </form>
  </div>
  </div>
  </div>