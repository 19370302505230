import {Component, HostListener, OnInit} from '@angular/core';
import { faCog, faBars, faWrench, faFileImport, faDollarSign, faChartBar, faChartLine, faBuilding, faGlobeEurope, faPowerOff } from '@fortawesome/free-solid-svg-icons';
import { SharingService } from 'src/app/services/sharing.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {

  logo = '../../../../assets/images/imagotipo.svg';
  faBars = faBars;
  faCog = faCog;
  faWeb = faGlobeEurope;
  faAnalytics = faChartLine;
  faCenter = faBuilding;
  faPower = faPowerOff;
  faFileImport = faFileImport;


  constructor(

    public _ss:SharingService,
    private route: Router
  ) { }

  ngOnInit(): void {
  }

  openMenu(){
    console.log("abriendo menú");
    this._ss.isOpen = !this._ss.isOpen;
    if(this._ss.isOpen){
      this._ss.marginLeft = '300px';
    }else{
      this._ss.marginLeft = '50px';
      this._ss.padding = '15px';
    }
  }

  closeSession(){
    this._ss.marginLeft = '0';
    this._ss.padding = '0';
    this._ss.cleanAll();
    this.route.navigateByUrl("/login");
  }

}
