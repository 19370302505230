import { Component } from '@angular/core';
import { SharingService } from './services/sharing.service';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent {
  title = 'MotrioBrand360';

  constructor(
    public _ss: SharingService,
    public translate: TranslateService
  ){  
    if(this._ss.userLogged){
      this.translate.setDefaultLang('es');
    }
  }
}
