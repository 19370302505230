import { Component, OnInit } from '@angular/core';
import { SharingService } from 'src/app/services/sharing.service';
import { NotifierService } from 'angular-notifier';
import { ApserviceService } from 'src/app/services/apservice.service';
import { FormControl, Validators, FormBuilder, FormGroup } from '@angular/forms';
import { CustomValidators } from 'ng2-validation-nor';

@Component({
  selector: 'app-brand-configuration',
  templateUrl: './brand-configuration.component.html',
  styleUrls: ['./brand-configuration.component.scss']
})
export class BrandConfigurationComponent implements OnInit {
  
  valForm: FormGroup;
  public validators;
  public validatorPhone;
  public va

  constructor(
      fb: FormBuilder,
      public _ss: SharingService,
      public _ns: NotifierService,
      private _ap: ApserviceService
  ) { 
    this._ss.newButton = false;
    this._ss.saveButton = true;
    this._ss.saveExitButton = false;
    this._ss.printButton = false;
    this._ss.saveNewButton = false;
    this._ss.backButton = false;
    this._ss.titleSection = 'Configuración';
    
    let password = new FormControl('', Validators.required);
    let certainPassword = new FormControl('', CustomValidators.equalTo(password));


    // Model Driven validation
    this.valForm = fb.group({
      'actualPassword': [null, Validators.required],
      'passwordGroup': fb.group({
        newPassword: password,
        confirmPassword: certainPassword
      })
    });
    }


  

  ngOnInit(): void {

  }

  save($ev, value: any){
    if (this.valForm.valid) {
        let config = {id:this._ss.getBrandSettings().id, old_password:value['actualPassword'],
        new_password:value['passwordGroup']['confirmPassword'], email:this._ss.getBrandSettings().email};
        this._ap.changePassword(config).subscribe((response)=>{
          if(response.result == 'OK'){
            console.log(response.msg);
            this._ns.notify('success','Guardado correctamente!');
          }else{
            this._ns.notify('error','Error: Ops, intentalo de nuevo más tarde!');
          }
        },(err)=>{
          this._ns.notify('error','Error: Ops, error en el servicio!');
        });
      }
    }
}
