import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders} from '@angular/common/http';
import { SharingService } from './sharing.service';
import {Observable, throwError} from 'rxjs';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/timeout';
import {environment} from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApserviceService {


  private url = environment.URL.host_backend + '/';
  private publicURL = environment.URL.host_backend + environment.URL.endpoint_public;  // URL to web api
  private adminUrl = environment.URL.host_backend + environment.URL.endpoint_admin;
  public  driverURL =  environment.URL.host_backend + environment.URL.endpoint_driver;
  public imgURL =  environment.URL.image_url; // URL static upload folder

  constructor(
    private http: HttpClient,
    private sharingService: SharingService
  ) { }

  private getHeadersPublic(): HttpHeaders {
    const headers = new HttpHeaders()
    .set('api-key', '0b9f87d3-c41d-4d21-aa59-34a2c591a632')
    .set('Authorization', this.sharingService.getBrandSettings() ? this.sharingService.getBrandSettings().token : '');
    return headers;
  }


  login(email, password): Observable<any> {
    return this.http.put<any>(this.url + 'login', {email, password},
    { headers: this.getHeadersPublic() }).catch(this.handleError);
  }

  changePassword(config): Observable<any> {
    return this.http.put<any>(this.url + 'change_pass', config,
    { headers: this.getHeadersPublic() }).catch(this.handleError);
  }

  /*Configuracion Taller */
  getMultisiteSettings(): Observable<Promise<any>> {
    return this.http.get<any>(this.publicURL + 'settings/' + this.sharingService.getBrandSettings().id,
    { headers: this.getHeadersPublic() }).
      catch(this.handleError).
      map((response: Response) => response.json()).
      catch(this.handleError);
  }

  getPromotions(): Observable<any> {
    return this.http.get<any>(this.publicURL + 'brand/promotions/' + this.sharingService.getBrandSettings().id,
    { headers: this.getHeadersPublic() }).catch(this.handleError);
  }

  addPromotion(promotion): Observable<any> {
    return this.http.post<any>(this.adminUrl + 'brand/promotion/', {id_brand: this.sharingService.getBrandSettings().id,
        id: promotion.id, title: promotion.title,
      summary: promotion.summary, description: promotion.description, image: promotion.image, banner_image: promotion.banner_image,
        start_date: promotion.start_date,
      expiration_date: promotion.expiration_date, status: promotion.status, featured: promotion.featured },
    { headers: this.getHeadersPublic() }).catch(this.handleError);
  }


  deletePromotion(id): Observable<any> {
    return this.http.delete<any>(this.adminUrl + 'brand/promotion/' + id,
    { headers: this.getHeadersPublic() }).catch(this.handleError);
  }

  changePromotionStatus(id): Observable<any> {
    return this.http.put<any>(this.adminUrl + 'brand/promotion/' + id,
    { headers: this.getHeadersPublic() }).catch(this.handleError);
  }

  setBrandPromotionFeatured(id): Observable<any> {
    return this.http.put<any>(this.adminUrl + 'brand/promotion/featured/' + id,
    { headers: this.getHeadersPublic() }).catch(this.handleError);
  }

  getBrandServices(): Observable<any> {
    return this.http.get<any>(this.publicURL + 'brand/services/' + this.sharingService.getBrandSettings().id,
    { headers: this.getHeadersPublic() }).catch(this.handleError);
  }

  getServices(): Observable<any> {
    return this.http.get<any>(this.publicURL + '/services/',
    { headers: this.getHeadersPublic() }).catch(this.handleError);
  }

  setBrandService(service): Observable<any> {
    return this.http.put<any>(this.adminUrl + 'brand/service/', {service},
    { headers: this.getHeadersPublic() }).catch(this.handleError);
  }

  setServiceStatus(service): Observable<any> {
    return this.http.put<any>(this.adminUrl + 'brand/service/status', {service},
    { headers: this.getHeadersPublic() }).catch(this.handleError);
  }

  getWorkshopCenters(): Observable<any> {
    return this.http.get<any>(this.publicURL + '/workshopsbrand/',
    { headers: this.getHeadersPublic() }).catch(this.handleError);
  }

  getWorkshopDetails(id): Observable<any> {
    return this.http.get<any>(this.publicURL + '/workshopsbrand/' + id,
    { headers: this.getHeadersPublic() }).catch(this.handleError);
  }

  addWorkshop(workshop): Observable<any> {
    return this.http.post<any>(this.adminUrl + '/workshops', {workshop},
    { headers: this.getHeadersPublic() }).catch(this.handleError);
  }

  modifyWorkshop(workshop): Observable<any> {
    return this.http.put<any>(this.adminUrl + '/workshops', {workshop},
    { headers: this.getHeadersPublic() }).catch(this.handleError);
  }

  getWorkshopServices(): Observable<any> {
    return this.http.get<any>(this.publicURL + '/motrio/services',
    { headers: this.getHeadersPublic() }).catch(this.handleError);
  }

  postRatesFile(ratesFile): Observable<any> {

    // Create form data
    const formData: FormData = new FormData();

    // Store form name as "file" with file data
    formData.append('ratesFile', ratesFile, ratesFile.name);

    // Make http post request over api
    // with formData as req
    const headers = this.getHeadersPublic()
      .delete('Content-Type')
      .append('enctype', 'multipart/form-data');

    return this.http.post<any>(this.publicURL + '/rates-file', formData,
      { headers }).catch(this.handleError);
  }

  public handleError(error: any): Observable<never> {    // log error
    console.log('Error en com service');
    console.log(error);
    // throw an application level error
    return throwError(error);
  }
}
