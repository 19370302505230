import { Component, OnInit, OnDestroy, ViewChild, ElementRef, NgZone, OnChanges } from '@angular/core';
import { SharingService } from 'src/app/services/sharing.service';
import { NotifierService } from 'angular-notifier';
import { ApserviceService } from 'src/app/services/apservice.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Workshop } from '../../../models/workshop';
import { TranslateService } from '@ngx-translate/core';
import { faChevronUp } from '@fortawesome/free-solid-svg-icons';
import * as moment from 'moment';
import {FormBuilder, FormGroup, Validators, FormControl, AbstractControl, FormArray} from '@angular/forms';
import { MapsAPILoader } from '@agm/core';


export type TimetableRow = {
  id: string,
  workshopId: string,
  day: string,
  startMorningHour: string,
  endMorningHour: string,
  startAfternoonHour: string,
  endAfternoonHour: string
};

@Component({
  selector: 'app-brand-modify-workshop',
  templateUrl: './brand-modify-workshop.component.html',
  styleUrls: ['./brand-modify-workshop.component.scss']
})
export class BrandModifyWorkshopComponent implements OnInit, OnChanges, OnDestroy {

  displayedColumns: string[] = ['day', 'startMorningHour', 'endMorningHour', 'startAfternoonHour', 'endAfternoonHour'];

  centerTimetables: TimetableRow[] = [
    {id: null, workshopId: '0', day: 'Monday', startMorningHour: '', endMorningHour: '', startAfternoonHour: '', endAfternoonHour: ''},
    {id: null, workshopId: '0', day: 'Tuesday', startMorningHour: '', endMorningHour: '', startAfternoonHour: '', endAfternoonHour: ''},
    {id: null, workshopId: '0', day: 'Wednesday', startMorningHour: '', endMorningHour: '', startAfternoonHour: '', endAfternoonHour: ''},
    {id: null, workshopId: '0', day: 'Thursday', startMorningHour: '', endMorningHour: '', startAfternoonHour: '', endAfternoonHour: ''},
    {id: null, workshopId: '0', day: 'Friday', startMorningHour: '', endMorningHour: '', startAfternoonHour: '', endAfternoonHour: ''},
    {id: null, workshopId: '0', day: 'Saturday', startMorningHour: '', endMorningHour: '', startAfternoonHour: '', endAfternoonHour: ''},
    {id: null, workshopId: '0', day: 'Sunday', startMorningHour: '', endMorningHour: '', startAfternoonHour: '', endAfternoonHour: ''}
  ];

  dataSource = this.centerTimetables;

  faChevronUp = faChevronUp;

  workshop: Workshop = new Workshop();
  tab = [{
    name: 'company',
    status: true
  }, {
    name: 'brand',
    status: false
  },
  {
    name: 'services',
    status: true
  },
  {
    name: 'timetables',
    status: false
  }];

  newWorkshop = false;
  services;

  saveSubscriptionButton;
  saveExitSubscriptionButton;
  saveNewSubscriptionButton;

  submitted = false;
  workshopForm: FormGroup;

  private geoCoder;
  zoom;




  // imageUrl = 'assets/images/workshop_dummy.jpg';

  @ViewChild('streetName')
  public searchElementRef: ElementRef;


  constructor(
    public sharingService: SharingService,
    public notifierService: NotifierService,
    private apserviceService: ApserviceService,
    private router: Router,
    private formBuilder: FormBuilder,
    public translate: TranslateService,
    private activatedRouter: ActivatedRoute,
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone
  ) {
    this.sharingService.saveButton = true;
    this.sharingService.saveNewButton = true;
    this.sharingService.saveExitButton = true;
    this.sharingService.backButton = true;
    this.sharingService.newButton = false;
    this.sharingService.printButton = false;
    this.sharingService.titleSection = 'Modificando taller';
    this.activatedRouter.params.subscribe((params) => {
      console.log(params);
      if (params.id && params.id !== 'new'){
        this.workshop.workshopId = params.id;
        this.newWorkshop = false;
      }else{
        this.newWorkshop = true;
        this.sharingService.titleSection = 'Nuevo Taller';
        this.workshop = new Workshop();
        this.workshop.createdAt = moment().format('YYYY-MM-DD');
        if ('geolocation' in navigator) {

          navigator.geolocation.getCurrentPosition((position) => {
            this.workshop.address.latitude = position.coords.latitude;
            this.workshop.address.longitude = position.coords.longitude;
            this.workshopForm.controls.address.setValue({
              streetName: '',
              location: '',
              province: '',
              postalCode: '',
              latitude: position.coords.latitude,
              longitude: position.coords.longitude
            });
            this.zoom = 12;
          });
        }
      }
    });
  }

  ngOnInit(): void {
    this.workshopForm = this.formBuilder.group({
      workshopId: [''],
      taxId: ['', Validators.required],
      tradeName: ['', Validators.required],
      commercialName: ['', Validators.required],
      address: this.formBuilder.group({
        streetName: ['', Validators.required],
        location: ['', Validators.required],
        province: ['', Validators.required],
        postalCode: ['', Validators.required],
        latitude: ['', Validators.required],
        longitude: ['', Validators.required],
      }),
      phone: ['', Validators.required],
      email: ['', Validators.required],
      fax: [''],
      web: [''],
      appointment: [''],
      facebookUrl: [''],
      instagramUrl: [''],
      linkedinUrl: [''],
      youtubeUrl: [''],
      laboursRate: [0, [this.numberValidator]],
      cta: [0],
      dms: [''],
      apolo: [''],
      tresorWorkshop: [''],
      ctaRen: [0],
      tresorR1: [''],
      brandEmail: [''],
      renEmail: [''],
      createdAt: [moment().format('YYYY-MM-DD')],
      finishedAt: [''],
      implanted: [false],
      iAmImplemented: [false],
      timetableRows: this.formBuilder.array([])
    });
debugger;
    this.centerTimetables.forEach(day => {
      day.workshopId = null;
      const row = this.initializeTableRow(day);
      (this.workshopForm.get('timetableRows') as FormArray).push(row);
    });

    this.mapsAPILoader.load().then(() => {
    this.geoCoder = new google.maps.Geocoder();
    const autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement);
    autocomplete.addListener('place_changed', () => {
        this.ngZone.run(() => {
          // get the place result
          const place: google.maps.places.PlaceResult = autocomplete.getPlace();

          // verify result
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }

          // set latitude, longitude and zoom
          const latitude = place.geometry.location.lat();
          const longitude = place.geometry.location.lng();
          this.zoom = 12;
          const address = place.address_components;
          this.workshop.address.latitude = latitude;
          this.workshop.address.longitude = longitude;
          this.workshopForm.controls.address.setValue({
            streetName: '',
            location: '',
            province: '',
            postalCode: '',
            longitude,
            latitude
          });
          console.log(latitude);
          console.log(longitude);
          console.log(place);
          this.getgoogleAddress(address);
        });
      });
    });

    // Subscripción Botón save Guardar

    this.saveSubscriptionButton = this.sharingService.$save.subscribe(() => {
      if (this.newWorkshop){
        this.save();
        this.router.navigateByUrl('/talleres');
      }else{
        this.modify();
      }
    });

    // Subscripción Botón Guardar / Nuevo
    this.saveNewSubscriptionButton = this.sharingService.$saveNew.subscribe(() => {
      if (this.newWorkshop){
        this.save();
        this.workshopForm.reset();
        this.router.navigateByUrl('/talleres/new');
        this.getServices();
      }else{
        this.modify();
        this.workshopForm.reset();
        this.router.navigateByUrl('/talleres/new');
        this.getServices();
      }
    });

    // Subscripción Botón Guardar / Salir
    this.saveExitSubscriptionButton = this.sharingService.$saveExit.subscribe(() => {
      if (this.newWorkshop){
        this.save();
        this.router.navigateByUrl('/talleres');
      }else{
        this.modify();
        this.router.navigateByUrl('/talleres');
      }
    });

    if (!this.newWorkshop){
        this.getWorkshop();
    }else{
        this.getServices();
    }
  }


  // Custom Validator
  numberValidator(control: AbstractControl): { [key: string]: boolean } | null {
    if (!control.value) {
      return null;
    }
    const regex = new RegExp(/^\d+(\.\d+)?$/);
    const valid = regex.test(control.value);

    return valid ? null : { invalidNumber: true };
  }

  initializeTableRow(day: any): FormGroup {
    return this.formBuilder.group({
      id: [day.id],
      workshopId: [day.workshopId],
      day: [day.day],
      startMorningHour: [day.startMorningHour],
      endMorningHour: [day.endMorningHour],
      startAfternoonHour: [day.startAfternoonHour],
      endAfternoonHour: [day.endAfternoonHour],
    });
  }

  get f(): { [p: string]: AbstractControl } { return this.workshopForm.controls; }

  changeActivateTab(tabName: string): void {
    // this.tab = this.tab.filter(x => x.name != 'company');
    this.tab.forEach(tab => {
        tab.status = false;
    });
    this.tab.find(x => x.name === tabName).status = true;
  }

  isActive(tabName): boolean {
    return this.tab.find(x => x.name === tabName).status;
  }

  showCompany(): void {
    this.tab.find(x => x.name === 'company').status = !this.tab.find(x => x.name === 'company').status;
  }

  getgoogleAddress(address: any): void {
    console.log(address);
    console.log(address.length);
    switch (address.length){
      case 1:
      break;
      case 2:
      break;
      case 3:
      break;
      case 4:
        this.workshopForm.controls.address.setValue({
          streetName: address[0].long_name,
          location: address[1].long_name,
          province: address[2].long_name,
          postalCode: address[3].long_name,
          latitude: this.workshop.address.latitude,
          longitude: this.workshop.address.longitude
        });
        break;

      case 5:
        if (address[0].types[0] === 'route'){
          console.log('entre aqui');
          this.workshopForm.controls.address.setValue({
            streetName: address[0].long_name,
            location: address[1].long_name,
            province: address[2].long_name,
            postalCode: address[5].long_name,
            latitude: this.workshop.address.latitude,
            longitude: this.workshop.address.longitude
          });
        }else{
          this.workshopForm.controls.address.setValue({
            streetName: '',
            location: address[1].long_name,
            province: address[2].long_name,
            postalCode: address[0].long_name,
            latitude: this.workshop.address.latitude,
            longitude: this.workshop.address.longitude
          });
        }
        break;

      case 6:
        if (address[0].types[0] === 'street_number'){
          this.workshopForm.controls.address.setValue({
            streetName:  address[1].long_name + ', ' + address[0].long_name,
            location: address[2].long_name,
            province: address[3].long_name,
            postalCode: address[5].long_name,
            latitude: this.workshop.address.latitude,
            longitude: this.workshop.address.longitude
          });
        }else{
          this.workshopForm.controls.address.setValue({
            streetName: address[0].long_name,
            location: address[1].long_name,
            province: address[2].long_name,
            postalCode: '',
            latitude: this.workshop.address.latitude,
            longitude: this.workshop.address.longitude
          });
        }
        break;
      default:
        this.workshopForm.controls.address.setValue({
          streetName: address[1].long_name + ', ' + address[0].long_name,
          location: address[2].long_name,
          province: address[3].long_name,
          postalCode: address[6].long_name,
          latitude: this.workshop.address.latitude,
          longitude: this.workshop.address.longitude
        });
    }

    console.log(address);
  }

  getServices(): void {
    this.apserviceService.getWorkshopServices().subscribe((response) => {
      if (response.result === 'OK'){
        debugger;
        console.log(response.msg);
        this.services = response.data;
        console.log(this.workshop);
        console.log(this.services);
        if (this.workshop.taxId){
          this.checkServices();
        }else{
          this.services.forEach(service => {
              const elt = {type: service.serviceId, hasService: false};
              this.workshop.services.push(elt);
          });
        }
      }else{
        console.log(response.msg);
        this.notifierService.notify('error', 'Error: al cargaro los servicios!');
      }
    }, (err) => {
      this.sharingService.handleError(err, err.mesage || 'Error: conexion con el servicio');
    });
  }

  checkServices(): void {
    this.services.forEach(service => {
      service.hasService = false;
      const i = this.workshop.services.findIndex(x => x.type === service.serviceId);
      if (i !== -1){
        console.log(this.workshop.services.find(x => x.type === service.serviceId).hasService);
        service.hasService = this.workshop.services.find(x => x.type === service.serviceId).hasService;
      }
      else{
        const serviceToAdd = {
          type: service.serviceId,
          hasService: false
        };
        this.workshop.services.push(serviceToAdd);
      }
      console.log(service);
    });
  }

  getWorkshop(): void {
    this.apserviceService.getWorkshopDetails(this.workshop.workshopId).subscribe((response) => {
      if (response.result === 'OK'){
        debugger;
        console.log(response.msg);
        this.workshop = response.data;
        this.workshop.address.latitude = Number(this.workshop.address.latitude);
        this.workshop.address.longitude = Number(this.workshop.address.longitude);
        this.workshopForm.controls.workshopId.setValue(this.workshop.workshopId);
        this.workshopForm.controls.taxId.setValue(this.workshop.taxId);
        this.workshopForm.controls.tradeName.setValue(this.workshop.tradeName);
        this.workshopForm.controls.commercialName.setValue(this.workshop.commercialName);
        this.workshopForm.controls.address.setValue({
          streetName: this.workshop.address.streetName,
          location: this.workshop.address.location,
          province: this.workshop.address.province,
          postalCode: this.workshop.address.postalCode,
          latitude: this.workshop.address.latitude,
          longitude: this.workshop.address.longitude
         });
        this.workshopForm.controls.phone.setValue(this.workshop.phone);
        this.workshopForm.controls.email.setValue(this.workshop.email);
        this.workshopForm.controls.fax.setValue(this.workshop.fax);
        this.workshopForm.controls.web.setValue(this.workshop.web);
        this.workshopForm.controls.appointment.setValue(this.workshop.appointment);
        this.workshopForm.controls.facebookUrl.setValue(this.workshop.facebookUrl);
        this.workshopForm.controls.instagramUrl.setValue(this.workshop.instagramUrl);
        this.workshopForm.controls.linkedinUrl.setValue(this.workshop.linkedinUrl);
        this.workshopForm.controls.youtubeUrl.setValue(this.workshop.youtubeUrl);
        this.workshopForm.controls.laboursRate.setValue(this.workshop.laboursRate);
        this.workshopForm.controls.createdAt.setValue(this.workshop.createdAt);
        this.workshopForm.controls.finishedAt.setValue(this.workshop.finishedAt);
        this.workshopForm.controls.cta.setValue(this.workshop.cta);
        this.workshopForm.controls.dms.setValue(this.workshop.dms);
        this.workshopForm.controls.apolo.setValue(this.workshop.apolo);
        this.workshopForm.controls.tresorWorkshop.setValue(this.workshop.tresorWorkshop);
        this.workshopForm.controls.ctaRen.setValue(this.workshop.ctaRen);
        this.workshopForm.controls.tresorR1.setValue(this.workshop.tresorR1);
        this.workshopForm.controls.brandEmail.setValue(this.workshop.brandEmail);
        this.workshopForm.controls.renEmail.setValue(this.workshop.renEmail);
        this.workshopForm.controls.implanted.setValue(this.workshop.implanted);
        this.workshopForm.controls.iAmImplemented.setValue(this.workshop.iAmImplemented);
        this.getServices();

        (this.workshopForm.get('timetableRows') as FormArray).clear();

        // Obtención de los horarios
        if (this.workshop.timetableRows.length){
          this.workshop.timetableRows = this.orderCenterTimetables(this.workshop.timetableRows);
          this.workshop.timetableRows.forEach(day => {
            day.workshopId = this.workshop.workshopId;
            const row = this.initializeTableRow(day);
            (this.workshopForm.get('timetableRows') as FormArray).push(row);
          });
          // this.workshopForm.controls.timetableRows.setValue(this.workshop.timetableRows);
        }
        else{
          this.centerTimetables.forEach(day => {
            day.workshopId = this.workshop.workshopId;
            const row = this.initializeTableRow(day);
            (this.workshopForm.get('timetableRows') as FormArray).push(row);
          });
          // this.workshopForm.controls.timetableRows.value.find(row => row.workshopId = this.workshop.workshopId);
        }

      }else{
         console.log(response.msg);
         this.notifierService.notify('error', 'Oops.. Error: al cargar los detalles de taller.');
      }
    });
  }

  // tslint:disable-next-line:typedef
  orderCenterTimetables(rows){
    debugger;
    const weekDays = [
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
      'Sunday',
    ];
    rows.sort((a, b) => {
      return weekDays.indexOf(a.day) - weekDays.indexOf(b.day);
    });
    return rows;
  }

  changeServiceStatus(id): void {
    console.log(id);
    this.services.find(x => x.serviceId === id).hasService = !this.services.find(x => x.serviceId === id).hasService;
    this.workshop.services.find(x => x.type === id).hasService = !this.workshop.services.find(x => x.type === id).hasService;
  }

  changeWorkshopImplanted(event): void {
    this.workshop.implanted = event.target.checked;
  }

  changeWorkshopIAmImplemented(event): void {
    this.workshop.iAmImplemented = event.target.checked;
  }

  save(): void {
    this.submitted = true;
    // stop here if form is invalid
    if (this.workshopForm.invalid) {
      this.notifierService.notify('error', 'Oops.. Debe cubrir los campos son obligatorios!');
      return;
    }
    this.workshopForm.addControl('services', new FormControl(''));
    this.workshopForm.controls.services.setValue(this.workshop.services);


    this.saveWorkShop();
  }


  modify(): void {
    debugger;
    this.submitted = true;
    // stop here if form is invalid
    if (this.workshopForm.invalid) {
      this.notifierService.notify('error', 'Oops.. Debe cubrir los campos son obligatorios!');
      return;
    }

    this.workshopForm.addControl('services', new FormControl(''));
    this.workshopForm.controls.services.setValue(this.workshop.services);
    this.modifyWorkshop();
  }

  saveWorkShop(): void {
    this.apserviceService.addWorkshop(this.workshopForm.value).subscribe((response) => {
      if (response.result === 'OK'){
        console.log(response.msg);
        this.notifierService.notify('success', 'Guardado correctamente!');
      }else{
        console.log(response.msg);
        this.notifierService.notify('error', 'Oops... Error al añadir el taller');
      }
    }, (err) => {
      this.sharingService.handleError(err, err.msg, 'Error: se ha producido un error en el servicio');
    });
  }

  modifyWorkshop(): void {
    console.log(this.workshopForm.value);
    this.apserviceService.modifyWorkshop(this.workshopForm.value).subscribe((response) => {
      if (response.result === 'OK'){
        console.log(response.msg);
        this.notifierService.notify('success', 'Guardado correctamente!');
      }else{
        console.log(response.msg);
        this.notifierService.notify('error', 'Oops... Error al modificar los datos del taller');
      }
    }, (err) => {
      this.sharingService.handleError(err, err.msg, 'Error: se ha producido un error en el servicio');
    });

  }

  ngOnChanges(): void {
    console.log('cambiando...');
    this.workshopForm.controls.address.setValue({
      streetName: '',
      location: '',
      province: '',
      postalCode: '',
      latitude: this.workshop.address.latitude,
      longitude: this.workshop.address.longitude
    });
  }

  ngOnDestroy(): void {
    this.saveSubscriptionButton.unsubscribe();
    this.saveExitSubscriptionButton.unsubscribe();
    this.saveNewSubscriptionButton.unsubscribe();
  }
}
