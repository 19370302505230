import {Address} from './address';
import * as moment from 'moment';
import {TimetableRow} from '../components/brand-workshops/brand-modify-workshop/brand-modify-workshop.component';
export class Workshop {
    workshopId: string;
    taxId: string;
    tradeName: string;
    commercialName: string;
    address: Address;
    services: Array<{
      type: number,
      hasService: boolean
    }>;
    activeServiceIds: Array<{}>;
    createdAt: string;
    finishedAt: string;
    apolo: string;
    tresorWorkshop: string;
    brandEmail: string;
    renEmail: string;
    cta: number;
    ctaRen: number;
    tresorR1: string;
    dms: string;
    phone: string;
    email: string;
    fax: string;
    web: string;
    appointment: string;
    facebookUrl: string;
    instagramUrl: string;
    linkedinUrl: string;
    youtubeUrl: string;
    laboursRate: number;
    implanted: boolean;
    iAmImplemented: boolean;
    timetableRows: TimetableRow[];


      constructor(w?: Workshop) {
        this.workshopId = w && w.workshopId;
        this.taxId = w && w.taxId;
        this.tradeName = w && w.tradeName;
        this.commercialName = w && w.commercialName;
        this.address = w && w.address || new Address();
        this.services = w && w.services || new Array();
        this.activeServiceIds = w && w.activeServiceIds || new Array();
        this.createdAt = w && w.createdAt || moment().format('YYYY-MM-DD');
        this.finishedAt = w && w.finishedAt;
        this.apolo = w && w.apolo;
        this.tresorR1 = w && w.tresorR1;
        this.brandEmail = w && w.brandEmail;
        this.renEmail = w && w.renEmail;
        this.cta = w && w.cta || 0;
        this.ctaRen = w && w.ctaRen || 0;
        this.tresorWorkshop = w && w.tresorWorkshop;
        this.dms = w && w.dms;
        this.phone = w && w.phone;
        this.email = w && w.email;
        this.fax = w && w.fax;
        this.web = w && w.web;
        this.appointment = w && w.appointment;
        this.facebookUrl = w && w.facebookUrl;
        this.instagramUrl = w && w.instagramUrl;
        this.youtubeUrl = w && w.youtubeUrl;
        this.laboursRate = w && w.laboursRate;
        this.implanted = w && w.implanted || false;
        this.iAmImplemented = w && w.iAmImplemented || false;
        this.timetableRows = w && w.timetableRows || [];
      }
  }
