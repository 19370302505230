<div class="content">
    <div class="form">
        <label for="user">
            <input type="text" name="" placeholder="usuario" />
        </label>
        <div class="row">
            <label for="password">
                <input type="password" name="password" placeholder="nueva contraseña" />
            </label>
            <label for="password">
                <input type="password" name="password2" placeholder="repite la contraseña" />
            </label>
        </div>
       
        <button>GUARDAR</button>
    </div>
</div>
