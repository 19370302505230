<div class="content">
    <div *ngFor="let service of services" class="service-target">
        <div class="title" (click)="getServiceDetails(service)">
            <div class="icon">
                <span [inlineSVG]="service.image"></span>
            </div>
            <span>{{ service.title }}</span>
        </div>
        <div class="ribbon-btn" *ngIf="!service.status">
            <div class="ribbon" (click)="changeServiceStatus(service)">Desactivado</div>
        </div>
        <div class="ribbon-btn" *ngIf="service.status">
            <div class="ribbon-active" (click)="changeServiceStatus(service)">Activado</div>
        </div>
    </div>
</div>

<div class="form" [ngClass]="{open: isEditService}" #formservice>
    <div class="title">
        <h1>Modificar Servicio</h1>
        <span class="close" (click)="isEditService = false">
            <fa-icon [icon]="faClose"></fa-icon>
        </span>
    </div>
    <div class="container">
        <label for="title">Título</label>
        <input type="text" name="title" [(ngModel)]="service.title" placeholder="Título" disabled/>
        <div *ngIf="service.description_o">
            <label for="title">Descripción original</label>
            <p>
                {{ service.description_o }}
            </p>
        </div>
      
        <label for="title">Descripción</label>
        <angular-editor [placeholder]="'Introduce una descripción...'" [(ngModel)]="service.description" [config]="editorConfig"></angular-editor>
        <!--
        <div class="uploading">
            <label for="upload-file" class="custom-file-upload">
            <i class="fa fa-cloud-upload"></i>
          Seleccione una imagen</label>
            <span class="file-name">{{ filename }}</span>
            <input id="upload-file" #selectedPicture type="file" name="photo" ng2FileSelect [uploader]="uploader" />
            <div class="progress" *ngIf="isuploadingProgress">
                <p style="padding: 0; margin: 0">Archivo subido</p>
                <div class="progress-bar" [style.width]="uploadStatusProgress">
                    <p class="progress-content" role="progressbar"></p>
                </div>
                <span class="progress-num">{{ uploadStatusProgress }}</span>
            </div>
            <div class="drop-zone" [ngClass]="{'file-over': hasBaseDropZoneOver}" ng2FileDrop [uploader]="uploader" (click)="fileInput.click()">

                Por favor, elige una imagen
                <input type="file" #fileInput ng2FileSelect [uploader]="uploader" style="display: none" />
            </div>
        </div>
    -->
        <button class="btn" (click)="save()">EDITAR Y GUARDAR</button>
    </div>
</div>