<div class="container-menu">
   
    <ul class="nav" [ngClass]="!_ss.isOpen ? 'closed' : ''">
        <li>
            <div class="logo">
                <span class="social-icon" [inlineSVG]="logo"></span>
            </div>
        </li>
        
        <li (click)="_ss.isOpen = false">
            <a [ngClass]="_ss.isOpen ? 'open' : ''">
                <div class="icon">
                    <fa-icon [icon]="faBars"></fa-icon>
                </div>
                <span>{{ 'menu.close' | translate }}</span>
            </a>
        </li>

        <li>
            <a [routerLink]="['/promociones']" routerLinkActive="active">
                <div class="icon">
                    <fa-icon [icon]="faDolar"></fa-icon>
                </div>
                <span>{{ 'menu.campaigns' | translate }}</span>
            </a>
        </li>

        <li>
            <a [routerLink]="['/servicios']" routerLinkActive="active">
                <div class="icon">
                    <fa-icon [icon]="faWrench"></fa-icon>
                </div>
                <span>{{ 'menu.services' | translate }}</span>
            </a>
        </li>

    </ul>
</div>
