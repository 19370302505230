import { Component, OnInit } from '@angular/core';
import { NotifierService } from 'angular-notifier';
import { ApserviceService } from 'src/app/services/apservice.service';
import { SharingService } from 'src/app/services/sharing.service';

@Component({
  selector: 'app-product-rates',
  templateUrl: './product-rates.component.html',
  styleUrls: ['./product-rates.component.scss']
})
export class ProductRatesComponent implements OnInit {


  private productRatesServiceUrl = 'localhost:8080/';

  constructor(private _ap: ApserviceService,
    public _ss: SharingService,
    public _ns: NotifierService) {
  }

  async sendFile() {
    console.info('sending file...');
    var ratesFile=(document.querySelector('#rates-file') as HTMLInputElement).files[0];
    // const reader = new FileReader();
    // var text = reader.addEventListener('load', () => {
    //   console.log(reader.result);
    //   // return this.http.post(this.publicURL + '/motrio/rates-file',reader.result,
    //   // { headers: this.getHeadersPublic() }).catch(this.handleError)
    //   // .map((response: Response) => response.json()).catch(this.handleError);
    // });
    // await reader.readAsText(ratesFile);
    // this._ap.postRatesFile();
    
    var ratesComponent =document.querySelector('app-product-rates');
    ratesComponent.classList.add("app-product-rates--loading");

    this._ap.postRatesFile(ratesFile).subscribe((response)=>{
      if(response.result == 'OK'){
        this._ns.notify('success','Fichero cargado correctamente!');
        console.log(response.msg);
      }else{
        console.log(response.msg);
        let apiErrorsDiv=document.querySelector("app-product-rates .api-errors");
        apiErrorsDiv.appendChild(document.createElement("p")).append(response.msg);
        this._ns.notify('error','Error: al cargar el fichero!');
      }
      ratesComponent.classList.remove("app-product-rates--loading");
    },(err)=>{
      let apiErrorsDiv=document.querySelector("app-product-rates .api-errors");
      apiErrorsDiv.innerHTML="";
      try{
        let errors=JSON.parse(err.error.msg);
        for(let apiError of errors){
          if(apiError.error){
            apiErrorsDiv.appendChild(document.createElement("p")).append(apiError.lineNumber + " "+ apiError.message);
          }
        }
      } catch{
        apiErrorsDiv.appendChild(document.createElement("p")).append(err.error.msg);
      }
      this._ns.notify('error','Error: al cargar el fichero');
      ratesComponent.classList.remove("app-product-rates--loading");
    });
    console.info('file sent');
  }

  ngOnInit(): void {
  }

}
