import { Component, OnInit, HostListener } from '@angular/core';
import { SharingService } from 'src/app/services/sharing.service';
import { faBars, faCog, faWrench, faFileImport, faDollarSign, faBuilding, faChartLine } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-submenu',
  templateUrl: './submenu.component.html',
  styleUrls: ['./submenu.component.scss']
})
export class SubmenuComponent implements OnInit {

  logo = '../../../../../assets/images/logo.svg';
  faBars = faBars;
  faCog = faCog;
  faWrench = faWrench;
  faFileImport = faFileImport;
  faDolar = faDollarSign;
  faAnalytics = faChartLine;
  faCenter = faBuilding;

  constructor(
    public _ss:SharingService
  ) { }

  ngOnInit(): void {
  }

}
