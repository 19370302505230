import { Component, OnInit } from '@angular/core';
import { SharingService } from '../../services/sharing.service';
import { Router } from '@angular/router';
import { ApserviceService } from 'src/app/services/apservice.service';
import { NotifierService } from 'angular-notifier';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  public email: string;
  public password: string;

  constructor(
              public _ap: ApserviceService,
              public _ss: SharingService,
              public _ns: NotifierService,
              public router: Router) { 
                this._ss.padding = '0';
                this._ss.marginLeft = '0';
                this._ss.setLoading(false);
              }

  ngOnInit() {
    if (this._ss.userLogged()) {
      this.router.navigate(['/bi']);
    }
  }

  login() {
    this._ap.login(this.email, this.password).subscribe((response) => {
        if (response.result == 'OK') {
            this._ns.notify('success','Login Ok');
            this._ss.setBrandSettings(response.data);
            this.router.navigateByUrl('/bi'); 
        } else {
          this._ns.notify('error','Oops, datos de acceso no válidos!');
        }
      }),
      ((error) => {
        console.log('Error en login!!');
        console.log(error);
        this._ss.handleError(error, error.msg || 'Se produjo un error en el login');
      });
  }

  recoverPass() {
    
  }


}
