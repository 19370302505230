<div class="container-menu">
   
    <ul class="nav closed" *ngIf="_ss.userLogged()">
        <li>
            <div class="logo">
                <span [inlineSVG]="logo"></span>
            </div>
        </li>
        
        <li>
            <a (click)="_ss.isOpen = false" routerLink="/bi" routerLinkActive="active">
                <div class="icon">
                    <fa-icon [icon]="faAnalytics"></fa-icon>
                </div>
                <span>{{ 'menu.bi' | translate }}</span>
            </a>
        </li>

        <li>
            <a (click)="_ss.isOpen = false" routerLink="/talleres" routerLinkActive="active">
                <div class="icon">
                    <fa-icon [icon]="faCenter"></fa-icon>
                </div>
                <span>{{ 'menu.centers' | translate }}</span>
            </a>
        </li>

        <li>
            <a (click)="openMenu()" [ngClass]="{active : _ss.isOpen}" routerLinkActive="active">
                <div class="icon">
                    <fa-icon [icon]="faWeb"></fa-icon>
                </div>
                <span>{{ 'menu.web' | translate }}</span>
            </a>
        </li>

        <li>
            <a [routerLink]="['/product-rates']" routerLinkActive="active">
                <div class="icon">
                    <fa-icon [icon]="faFileImport"></fa-icon>
                </div>
                <span>{{ 'menu.services' | translate }}</span>
            </a>
        </li>

        <!--
        <li>
            <a (click)="_ss.isOpen = false" routerLink="/configuracion" routerLinkActive="active">
                <div class="icon">
                    <fa-icon [icon]="faCog"></fa-icon>
                </div>
                <span>Configuración</span>
            </a>
        </li>
        -->

        <li>
            <a (click)="closeSession()">
                <div class="icon">
                    <fa-icon [icon]="faPower"></fa-icon>
                </div>
                <span>{{ 'menu.logout' | translate }}</span>
            </a>
        </li>

    </ul>
</div>

<app-submenu></app-submenu>