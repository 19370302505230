import { Injectable, EventEmitter } from '@angular/core';
import { environment } from 'src/environments/environment';
import { NotifierService } from 'angular-notifier';
import { Title, Meta } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class SharingService {

  public site_title = 'Motrio 360 Brand';

  public loading = false;
  loadingText = 'Cargando ...';
  loadingProgress = '';

  public isOpen:boolean =  false;
  public titleSection = '';

  //Theme Options
  public marginLeft = '50px';
  public padding = '0 15px';

  //Buttons
  public saveButton = false;
  public saveNewButton  = false;
  public saveExitButton = false;
  public newButton = false;
  public printButton = false;
  public backButton = false;

  //Header Visible
  headerStatus = true;

  public adminURL = environment.URL.host_backend + environment.URL.endpoint_admin;
  public imgservicesURL = environment.URL.image_services_url;
  $save = new EventEmitter();
  $saveExit = new EventEmitter();
  $saveNew = new EventEmitter();
  $create = new EventEmitter();
  $print = new EventEmitter();

  private storageName = 'BrandSettings';

  public tempWorkshops = [];

  settings = {id: 2, token:'', email: null, language: 'es'}

  constructor(
    public _ns: NotifierService,
    private titleService: Title,
    private meta: Meta
  ) { }

  saveEvent(){
    console.log("Guardando....");
    this.$save.emit();
  }

  saveExitEvent(){
    console.log("Guardando....");
    this.$saveExit.emit();
  }

  saveNewEvent(){
    console.log("Guardando....");
    this.$saveNew.emit();
  }

  printEvent(){
    console.log("Imprimiendo....");
    this.$print.emit();
  }

  createEvent(){
    console.log("Creando....");
    this.$create.emit();
  }

  setLoading(l: boolean) {
    this.loading = l;
  }

  isLoading() {
    return this.loading;
  }

  userLogged(): boolean {
    if (this.getBrandSettings() != null && this.getBrandSettings().id
      && this.getBrandSettings().id > 0) {
      return true;
    }
    return false;
  }

  clearBrandSettings() {
    localStorage.removeItem(this.storageName);
  }

  setBrandSettings(userData) {
    localStorage.setItem(this.storageName, JSON.stringify(userData));
  }

  getBrandSettings(){
    const userData = localStorage.getItem(this.storageName);
    return JSON.parse(userData);
  }

  cleanAll() {
    localStorage.clear();
  }

   // Set Title
   setTitle(newTitle: string) {
    this.titleService.setTitle( this.site_title+' | '+newTitle);
  }

  // Avaliable / Disable Header
  setHeaderVisible(status){
    this.headerStatus = status;
  }

  handleError(error, errorFront, errorBody?) {
    console.log(error);
    this.setLoading(false);
    this._ns.notify('error','Oops, '+errorFront+' !');
  }

}
