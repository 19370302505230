import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { SharingService } from './sharing.service';

@Injectable()
export class AuthGuardService implements CanActivate {

  constructor(
    private router: Router,
    public _ss: SharingService) {
    this.router = router;
  }

  canActivate() {
    if (this._ss.userLogged()) {
      return true;
    }

    this.router.navigate(['/login']);
    return false;
  }

}
