<div class="header">
    <div class="menu-options-left">
        <a *ngIf="_ss.backButton" class="menu-btn" (click)="onBack()" #backbutton>
            <div class="icon">
                <fa-icon [icon]="faArrowLeft"></fa-icon>
            </div>
            <span>Volver</span>
        </a>
    </div>
    <h1>{{ _ss.titleSection }}</h1>
  
    <div class="menu-options-right">
        <a *ngIf="_ss.printButton" class="menu-btn" (click)="onPrint()" #printbutton>
            <div class="icon">
                <img src="../../../../assets/images/icons/toolbar/toolbar-print.svg">
            </div>
            <span>{{ 'btn.print' | translate }}</span>
        </a>
        <a *ngIf="_ss.saveButton" class="menu-btn" (click)="onSave()" #savebutton>
            <div class="icon">
                <img src="../../../../assets/images/icons/toolbar/toolbar-save-and-edit.svg">
            </div>
            <span>{{ 'btn.save' | translate }}</span>
        </a>
        <a *ngIf="_ss.saveNewButton" class="menu-btn" (click)="onSaveNew()" #saveandnewbutton>
            <div class="icon">
                <img src="../../../../assets/images/icons/toolbar/toolbar-save-and-new.svg">
            </div>
            <span>{{ 'btn.save_new' | translate }}</span>
        </a>
        <a *ngIf="_ss.saveExitButton" class="menu-btn" (click)="onSaveExit()" #saveandexitbutton>
            <div class="icon">
                <img src="../../../../assets/images/icons/toolbar/toolbar-save-and-exit.svg">
            </div>
            <span>{{ 'btn.save_exit' | translate }}</span>
        </a>
        <a *ngIf="_ss.newButton" class="menu-btn" (click)="onCreate()" #newbutton>
            <div class="icon">
                <img src="../../../../assets/images/icons/toolbar/toolbar-add.svg">
            </div>
            <span>{{ 'btn.create' | translate }}</span>
        </a>
    </div>
</div>