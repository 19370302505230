
export class Address {
    streetName:string;
    location:string;
    province:string;
    postalCode:string;
    latitude: number;
    longitude: number;
  
      constructor (a?: Address) {
        this.streetName = a && a.streetName;
        this.location = a && a.location;
        this.province = a && a.province;
        this.postalCode = a && a.postalCode;
        this.latitude = a && a.latitude || 0;
        this.longitude = a && a.longitude || 0;
      }
  }
  