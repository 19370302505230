<div class="container">
    <div class="filter">
        <div class="search-container">
            <div class="search">
                <span class="icon">
                        <fa-icon [icon]="faSearch"></fa-icon>
                    </span>
                <input name="search" type="search" [(ngModel)]="search.text" placeholder="Buscar" (keyup)="filter()" />
                <span *ngIf="showClearFilter" class="clear" (click)="clearFilter()">
                    <fa-icon [icon]="faClose"></fa-icon>
                </span>
                <span class="arrow" (click)="showSearch()">
                    <fa-icon [icon]="faArrow"></fa-icon>
                </span>
            </div>
            <div class="container" [ngClass]="{show : isOpened}">
                <div class="row">
                    <div>
                        <label for="start_date">
                            Estado:
                        </label>
                        <select name="status" [(ngModel)]="search.status" (change)="filter()">
                                <option value="3">Todas</option>
                                <option value="1">Activadas</option>
                                <option value="0">Desactivadas</option>
                        </select>
                    </div>
                </div>
                <!--
                <div class="row">
                    <div>
                        <label for="start_date">
                                Fecha desde:
                            </label>
                        <input type="date" name="start_date" [(ngModel)]="search.create_date" (change)="filter()" />
                    </div>

                    <div>
                        <label for="end_date">
                                Fecha hasta:
                            </label>
                        <input type="date" name="end_date" [(ngModel)]="search.finished_date" (change)="filter()" />
                    </div>
                </div>-->
            </div>
        </div>
    </div>

    <ngx-datatable
        class="material"
        [rows]="_ss.tempWorkshops"
        [loadingIndicator]="loadingIndicator"
        [limit]="10"
        [columnMode]="'force'"
        [headerHeight]="50"
        [footerHeight]="50"
        [sorts]="[{prop: 'id', dir: 'desc'}]"
        [rowHeight]="'auto'"
        (activate)='onClick($event)'
        >
          <ngx-datatable-column prop="taxId" name="{{ 'taxId' | translate }}"></ngx-datatable-column>
          <ngx-datatable-column prop="commercialName" name="{{ 'tradeName' | translate }}"></ngx-datatable-column>
          <ngx-datatable-column  prop="address.location" name="{{ 'address.location' | translate }}"></ngx-datatable-column>
          <ngx-datatable-column prop="email" name="{{'email' | translate }}"></ngx-datatable-column>
          <ngx-datatable-column prop="phone" name="{{ 'phone' | translate }}"></ngx-datatable-column>
          <ngx-datatable-column prop="ctaRen" name="CTA REN"></ngx-datatable-column>
      <!--
      <ngx-datatable-column prop="created_at" name="CREADO">
          <ng-template let-value="value" ngx-datatable-cell-template>
            {{value | date : 'dd/MM/yyyy'}}
          
          </ng-template>
      </ngx-datatable-column>
      
      <ngx-datatable-column prop="status" name="HABILITADO">
        <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
            <ui-switch [checked]="value" (change)="changeUserStatus($event,row)"></ui-switch>
        </ng-template>
    </ngx-datatable-column>
    -->
    </ngx-datatable>
</div>

