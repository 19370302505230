import { Component, OnInit } from '@angular/core';
import { SharingService } from 'src/app/services/sharing.service';

@Component({
  selector: 'app-brand-bi',
  templateUrl: './brand-bi.component.html',
  styleUrls: ['./brand-bi.component.scss']
})
export class BrandBiComponent implements OnInit {

  constructor(
    public _ss: SharingService
  ) { 
    this._ss.setTitle('Motrio BI');
    this._ss.setHeaderVisible(false);
    this._ss.newButton = false;
    this._ss.saveButton = false;
    this._ss.saveExitButton = false;
    this._ss.printButton = false;
    this._ss.saveNewButton = false;
    this._ss.backButton = false;
    this._ss.titleSection = 'Motrio BI';
    this._ss.marginLeft = '50px';
    this._ss.padding = '0 15px';
  }

  ngOnInit(): void {
  }

}
