import { Component, OnInit, OnDestroy } from '@angular/core';
import { SharingService } from 'src/app/services/sharing.service';
import { ApserviceService } from 'src/app/services/apservice.service';
import { NotifierService } from 'angular-notifier';
import { Router } from '@angular/router';
import { faArrowCircleDown, faSearch, faChevronDown } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-brand-workshops',
  templateUrl: './brand-workshops.component.html',
  styleUrls: ['./brand-workshops.component.scss']
})
export class BrandWorkshopsComponent implements OnInit, OnDestroy {

  // Icons
  faArrow = faChevronDown;
  faSearch = faSearch;

  // Filter
  loadingIndicator = true;
  showClearFilter = false;
  isOpened = false;
  search = {
    text: null,
    status: 1,
    create_date: null,
    finished_date: null,
  };

  // Array Talleres
  workshops;

  newSubscription;

  constructor(
    private _ap: ApserviceService,
    public _ss: SharingService,
    public _ns: NotifierService,
    private router: Router
  ) { 
    this._ss.setTitle('Talleres');
    this._ss.setHeaderVisible(true);
    this._ss.saveButton = false;
    this._ss.saveExitButton = false;
    this._ss.printButton = false;
    this._ss.saveNewButton = false;
    this._ss.newButton = true;
    this._ss.backButton = false;
    this._ss.titleSection = 'Talleres';
  }

  ngOnInit(): void {
    this.getTalleresMotrio();
    this.newSubscription = this._ss.$create.subscribe(() => {
      this.router.navigateByUrl('/talleres/new');
    });
  }

  getTalleresMotrio(){
    this._ap.getWorkshopCenters().subscribe((response)=>{
      if(response.result == 'OK'){
        console.log(response.msg);
        this.workshops = response.data;
        this._ss.tempWorkshops = this.workshops;
        console.log(this._ss.tempWorkshops);
        this.loadingIndicator = false;
      }else{
        console.log(response.msg);
        this._ns.notify('error', 'Oops, se ha producido un error!');
      }
    }),((err)=>{
      this._ss.handleError(err, err.msg || 'Se produjo error al cargar los talleres!');
    });
  }

  onClick(event) { 
    console.log("haciendo clic");
    if (event.type === 'dblclick') {
      this.router.navigateByUrl('/talleres/' + event.row.workshopId);
    }
  }

  showSearch(){
    this.isOpened = !this.isOpened;
  }

  // Resetear Filtrado
  clearFilter(){
    this.search = {
      text: '',
      create_date: '',
      finished_date: '',
      status:3
    }
    this._ss.tempWorkshops = this.workshops;
    this.showClearFilter = false;
  }

  // Filtrado & Buscador
  filter(){
    this.showClearFilter = true; 
    this._ss.tempWorkshops = this.workshops;

    if(this.search.text){
      console.log(this.search);
      this._ss.tempWorkshops = this._ss.tempWorkshops.filter( workshop => {
        return workshop.commercialName.toLowerCase().includes(this.search.text.toLowerCase());
      });
 /*
      this._ss.tempWorkshops  = this._ss.tempWorkshops.filter((workshop) => {
        let search = this.search.text.toLowerCase();
        var values = Object.values(workshop);
        var flag = false
        values.forEach((val: any) => {
          if(val.includes(search)) {
               flag = true;
               return;
           }
         });
         if(flag) return workshop
    });
    */
     }else{
      this._ss.tempWorkshops = this._ss.tempWorkshops;
    }

    if(this.search.status){
      if(this.search.status == 0){
        this._ss.tempWorkshops = this._ss.tempWorkshops.filter(x => x.implanted == true);
      }
      
      if(this.search.status == 1){
        this._ss.tempWorkshops = this._ss.tempWorkshops.filter(x => x.implanted == false);
      }

      if(this.search.status == 3){
        this._ss.tempWorkshops = this._ss.tempWorkshops;
      }
    }
  }

  ngOnDestroy(){
    this.newSubscription.unsubscribe();
  }

}
