import { Component, OnInit, Input, NgZone, OnChanges } from '@angular/core';
import { MapsAPILoader, MouseEvent } from '@agm/core';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-brand-workshop-map',
  templateUrl: './brand-workshop-map.component.html',
  styleUrls: ['./brand-workshop-map.component.scss']
})
export class BrandWorkshopMapComponent implements OnInit, OnChanges {
  
  @Input() workshop;

  title: string;
  draggable = false;
  iconUrl: string;
  visible = true;
  openInfoWindow = true;
  opacity = 1;
  zIndex = 1;
  clickable = true;
  zoom: number = 8;

  private geoCoder;

  showAlert = true;
  faClose = faTimes;

  constructor(
    private ngZone: NgZone,
    private mapsAPILoader: MapsAPILoader
  ) { }

  ngOnInit(){
  }

  ngOnChanges(){
    //load Places Autocomplete
    this.mapsAPILoader.load().then(() => {
      if(!this.workshop.address){
        this.setCurrentLocation();
      }
      this.geoCoder = new google.maps.Geocoder;
    });
    this.showAlert = true;
  }

  // Get Current Location Coordinates
  private setCurrentLocation() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.workshop.address.latitude = position.coords.latitude;
        this.workshop.address.longitude = position.coords.longitude;
        this.workshop
        this.zoom = 8;
        this.getAddress(this.workshop.address.latitude, this.workshop.address.longitude);
      });
    }
  }

  markerDragEnd($event: MouseEvent) {
    console.log($event);
    let latitude = $event.coords.lat;
    let longitude = $event.coords.lng;
    this.workshop.address.latitude = latitude;
    this.workshop.address.longitude = longitude;
    this.getAddress(this.workshop.address.latitude, this.workshop.address.longitude);
  }

  getAddress(latitude, longitude) {
    this.geoCoder.geocode({ 'location': { lat: latitude, lng: longitude } }, (results, status) => {
      if (status === 'OK') {
        if (results[0]) {
          this.zoom = 12;
          let address = results[0].address_components;
          this.getgoogleAddress(address);
        } else {
          window.alert('No results found');
        }
      } else {
        window.alert('Geocoder failed due to: ' + status);
      }
    });
  }

  showInfo(infoWindow){

  }

  hideAlert(){
    this.showAlert = false;
  }

  getgoogleAddress(address: any){
    console.log(address);
    console.log(address.length);
    switch(address.length){
      case 1:
      break;
      case 2:
      break;
      case 3:
      break;
      case 4:
        this.workshop.address = {
          streetName: address[0].long_name,
          location: address[1].long_name,
          province: address[2].long_name,
          postalCode: address[3].long_name,
        };
      break;

      case 5:
        if(address[0].types[0] == 'route'){
          console.log("entre aqui");
          this.workshop.address = {
            streetName: address[0].long_name,
            location: address[1].long_name,
            province: address[2].long_name,
            postalCode: address[5].long_name,
          };
        }else{
          this.workshop.address = {
            streetName: '',
            location: address[1].long_name,
            province: address[2].long_name,
            postalCode: address[0].long_name,
          };
        }
      break;

      case 6:
        if(address[0].types[0] == 'street_number'){
          this.workshop.address = {
            streetName:  address[1].long_name+', '+address[0].long_name,
            location: address[2].long_name,
            province: address[3].long_name,
            postalCode: address[5].long_name,
          };
        }else{
          this.workshop.address = {
            streetName: address[0].long_name,
            location: address[1].long_name,
            province: address[2].long_name,
            postalCode: ''
          };
        }
      break;
      default:
        this.workshop.address = {
          streetName: address[1].long_name+', '+address[0].long_name,
          location: address[2].long_name,
          province: address[3].long_name,
          postalCode: ''
        };
    }

    console.log(address);
    console.log(this.workshop);
  }

}
