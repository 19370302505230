<div class="container">
    <form [formGroup]="workshopForm" (ngSubmit)="save()">
        <div class="form" *ngIf="workshop" [ngClass]="isActive('company') ? '' : 'collapse'">
            <h1>Datos de la empresa: <span *ngIf="!newWorkshop">{{ workshop.commercialName }} ( {{ workshop.taxId }} )</span>  <a (click)="showCompany()" [ngClass]="isActive('company') ? '' : 'collapse'"><fa-icon [icon]="faChevronUp"></fa-icon></a></h1>
            <div class="form-container">
              <!--<div class="workshop-image-div">
                <img [src]="imageUrl" alt="image description" style="width:100%;">
              </div>-->
                <div class="row">
                    <div class="form-group">
                        <label for="taxId">{{ 'cif' | translate}} :</label>
                            <input type="text" name="taxId" formControlName="taxId" #taxId placeholder="{{ 'cif' | translate}}" [ngClass]="{ 'is-invalid': submitted && f.taxId.errors }" required/>
                    </div>
                    <div class="form-group">
                        <label for="tradeName">{{ 'tradeName' | translate}} :</label>
                            <input type="text" name="tradeName" formControlName="tradeName" #tradeName placeholder="{{ 'tradeName' | translate}}" [ngClass]="{ 'is-invalid': submitted && f.tradeName.errors }" required/>

                    </div>
                    <div class="form-group">
                        <label for="commercialName">{{ 'commercialName' | translate}} :</label>
                            <input type="text" name="commercialName" formControlName="commercialName" #commercialName placeholder="{{ 'commercialName' | translate}}" [ngClass]="{ 'is-invalid': submitted && f.commercialName.errors }" required/>
                    </div>
                </div>

                <div class="row" formGroupName="address">
                    <div class="form-group">
                        <label for="streetName">{{ 'address.registeredoffice' | translate}} :</label>
                            <input type="text" name="streetName" formControlName="streetName" #streetName placeholder="{{ 'address.streetName' | translate}}" [ngClass]="{ 'is-invalid': submitted && workshopForm.get('address.streetName').errors }" required/>
                    </div>
                    <div class="form-group">
                        <label for="location">{{ 'address.location' | translate}} :</label>
                            <input type="text" name="location" formControlName="location" #location  placeholder="{{ 'address.location' | translate}}" [ngClass]="{ 'is-invalid': submitted && workshopForm.get('address.location').errors }" required/>
                    </div>
                    <div class="form-group">
                        <label for="province">{{ 'address.province' | translate}} :</label>
                            <input type="text" name="province" formControlName="province" #province  placeholder="{{ 'address.province' | translate}}" [ngClass]="{ 'is-invalid': submitted && workshopForm.get('address.province').errors }" required/>
                    </div>
                    <div class="form-group">
                        <label for="postalCode">{{ 'address.postalCode' | translate}} :</label>
                            <input type="text" name="postalCode" formControlName="postalCode" #postalCode  placeholder="{{ 'address.postalCode' | translate}}" [ngClass]="{ 'is-invalid': submitted && workshopForm.get('address.postalCode').errors }" required/>
                    </div>
                </div>

                <div class="row" formGroupName="address">
                    <div class="map">
                        <app-brand-workshop-map [workshop]="workshop"></app-brand-workshop-map>
                    </div>
                    <div class="">
                        <div class="">
                            <label for="latitude">{{ 'address.latitude' | translate}} :</label>
                                <input type="text" name="latitude" formControlName="latitude" #latitude  placeholder="{{ 'address.latitude' | translate}}" [ngClass]="{ 'is-invalid': submitted && workshopForm.get('address.latitude').errors }" />
                        </div>
                        <div class="">
                            <label for="longitude">{{ 'address.longitude' | translate}} :</label>
                                <input type="text" name="longitude" formControlName="longitude" #longitude placeholder="{{ 'address.longitude' | translate}}" [ngClass]="{ 'is-invalid': submitted && workshopForm.get('address.longitude').errors }" />
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="form-group">
                        <label for="phone">{{ 'phone' | translate}} :</label>
                            <input type="text" name="phone" formControlName="phone" #phone placeholder="{{ 'phone' | translate}}" [ngClass]="{ 'is-invalid': submitted && f.phone.errors }" />
                    </div>
                    <div class="form-group">
                        <label for="email">{{ 'email' | translate}} :</label>
                            <input type="text" name="email" formControlName="email" #email placeholder="{{ 'email' | translate}}" [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
                    </div>
                    <div class="form-group">
                        <label for="fax">{{ 'fax' | translate}} :</label>
                            <input type="text" name="fax" formControlName="fax" #fax placeholder="{{ 'fax' | translate}}" [ngClass]="{ 'is-invalid': submitted && f.fax.errors }" />
                    </div>
                    <div class="form-group">
                        <label for="web">{{ 'web' | translate}} :</label>
                            <input type="text" name="web" formControlName="web" #web placeholder="{{ 'web' | translate}}" [ngClass]="{ 'is-invalid': submitted && f.web.errors }" />
                    </div>
                    <div class="form-group">
                        <label for="appointment">{{ 'appointment' | translate}} :</label>
                            <input type="text" name="appointment" formControlName="appointment" #appointment placeholder="{{ 'appointment' | translate}}" [ngClass]="{ 'is-invalid': submitted && f.web.errors }" />
                    </div>
                </div>
                <div class="row">
                  <!--Añadir nuevos campos formulario-->
                  <div class="form-group">
                    <label for="facebookUrl">{{ 'facebookUrl' | translate}} :</label>
                    <input type="text" name="facebookUrl" formControlName="facebookUrl" #facebookUrl placeholder="{{ 'facebookUrl' | translate}}" [ngClass]="{ 'is-invalid': submitted && f.web.errors }" />
                  </div>

                  <div class="form-group">
                    <label for="instagramUrl">{{ 'instagramUrl' | translate}} :</label>
                    <input type="text" name="instagramUrl" formControlName="instagramUrl" #instagramUrl placeholder="{{ 'instagramUrl' | translate}}" [ngClass]="{ 'is-invalid': submitted && f.web.errors }" />
                  </div>

                  <div class="form-group">
                    <label for="linkedinUrl">{{ 'linkedinUrl' | translate}} :</label>
                    <input type="text" name="linkedinUrl" formControlName="linkedinUrl" #linkedinUrl placeholder="{{ 'linkedinUrl' | translate}}" [ngClass]="{ 'is-invalid': submitted && f.web.errors }" />
                  </div>

                  <div class="form-group">
                    <label for="youtubeUrl">{{ 'youtubeUrl' | translate}} :</label>
                    <input type="text" name="youtubeUrl" formControlName="youtubeUrl" #youtubeUrl placeholder="{{ 'youtubeUrl' | translate}}" [ngClass]="{ 'is-invalid': submitted && f.web.errors }" />
                  </div>
                  <div class="form-group">
                    <label for="laboursRate">{{ 'laboursRate' | translate}} :</label>
                    <input type="text" name="laboursRate" formControlName="laboursRate" #laboursRate placeholder="{{ 'laboursRate' | translate}}" [ngClass]="{ 'is-invalid': submitted && f.web.errors, 'invalid-field': workshopForm.controls.laboursRate.touched && workshopForm.controls.laboursRate.invalid }" />
                    <div *ngIf="workshopForm.controls.laboursRate.touched && workshopForm.controls.laboursRate.invalid">
                      El valor introducido no es un número válido
                    </div>
                  </div>
                </div>

                <div class="row">
                    <div class="form-group" *ngIf="newWorkshop">
                        <label for="createdAt" >{{ 'createdAt' | translate}} :</label>
                        <input type="date" name="createdAt" formControlName="createdAt" #createdAt placeholder="{{ 'createdAt' | translate}}" [ngClass]="{ 'is-invalid': submitted && f.createdAt.errors }" required/>
                    </div>
                    <div class="form-group" *ngIf="!newWorkshop">
                        <label for="createdAt">{{ 'createdAt' | translate}} :</label>
                        <input type="date" name="createdAt" formControlName="createdAt" #createdAt placeholder="{{ 'createdAt' | translate}}" [ngClass]="{ 'is-invalid': submitted && f.createdAt.errors }" />
                    </div>
                    <div class="form-group" *ngIf="!newWorkshop">
                        <label for="finishedAt">{{ 'finishedAt' | translate}} :</label>
                        <input type="date" name="finishedAt" formControlName="finishedAt" #finishedAt placeholder="{{ 'finishedAt' | translate}}" [ngClass]="{ 'is-invalid': submitted && f.finishedAt.errors }" />
                    </div>

                    <div class="form-group switch-field">
                        <label for="implanted">{{ 'implanted' | translate}} :</label>
                        <label class="switch">
                            <input type="checkbox" (change)="changeWorkshopImplanted($event)" formControlName="implanted" #implanted [checked]="workshop.implanted">
                            <span class="slider round"></span>
                        </label>
                    </div>
                    <div class="form-group switch-field">
                        <label for="iAmImplemented">{{ 'iamimplemented' | translate}} :</label>
                        <label class="switch">
                            <input type="checkbox" (change)="changeWorkshopIAmImplemented($event)" formControlName="iAmImplemented" #iAmImplemented [checked]="workshop.iAmImplemented">
                            <span class="slider round"></span>
                        </label>
                    </div>

                </div>

              <div class="row">


              </div>
            </div>

        </div>

        <div class="company-tabs">
            <div class="tabs">
                <ul>
                    <li (click)="changeActivateTab('services')" [ngClass]="isActive('services') ? 'active' : ''">{{ 'services' | translate }}</li>
                    <li (click)="changeActivateTab('brand')" [ngClass]="isActive('brand') ? 'active' : ''">{{ 'brandData' | translate }}</li>
                    <li (click)="changeActivateTab('timetables')" [ngClass]="isActive('timetables') ? 'active' : ''">{{ 'centerTimetable' | translate }}</li>
                </ul>
            </div>
            <div class="table">

                <!-- Workshop Brand Details -->
                <div  [ngStyle]="{'display': isActive('brand') ? '' : 'none'}">
                    <div class="row">
                        <div class="form-group">
                            <label for="cta">{{ 'renault_data.cta' | translate}}:</label>
                                <input type="text" name="cta" formControlName="cta" #cta placeholder="{{ 'renault_data.cta' | translate}}" [ngClass]="{ 'is-invalid': submitted && f.cta.errors }" required />
                        </div>
                        <div class="form-group">
                            <label for="dms">DMS:</label>
                                <input type="text" name="dms" formControlName="dms" #dms placeholder="{{ 'dms' | translate}}" />
                        </div>
                        <div class="form-group">
                            <label for="apolo">Apolo:</label>
                                <input type="text" name="apolo" formControlName="apolo" #apolo placeholder="{{ 'apolo' | translate}}" />
                        </div>
                        <div class="form-group">
                            <label for="tresorWorkshop">ID Tresor Taller:</label>
                                <input type="text" name="tresorWorkshop" formControlName="tresorWorkshop" #tresorWorkshop placeholder="{{ 'renault_data.tresorWorkshop' | translate}}" />
                        </div>
                        <div class="form-group">
                            <label for="ctaRen">{{ 'renault_data.ctaRen' | translate}}:</label>
                                <input type="text" name="ctaRen" formControlName="ctaRen" #ctaRen placeholder="{{ 'renault_data.ctaRen' | translate}}" [ngClass]="{ 'is-invalid': submitted && f.ctaRen.errors }" required />
                        </div>
                        <div class="form-group">
                            <label for="tresorR1">ID Tresor R1:</label>
                                <input type="text" name="tresorR1" formControlName="tresorR1" #tresorR1 placeholder="{{ 'renault_data.tresorR1' | translate}}" />
                        </div>
                    </div>
                    <div class="row">
                        <div class="form-group">
                            <label for="brandEmail">Email Motrio:</label>
                                <input type="text" name="brandEmail" formControlName="brandEmail" #brandEmail placeholder="{{ 'brandEmail' | translate}}" />
                        </div>
                        <div class="form-group">
                            <label for="renEmail">Email R1:</label>
                                <input type="text" name="renEmail" formControlName="renEmail" #renEmail  placeholder="{{ 'renEmail' | translate}}" />
                        </div>
                    </div>
                </div>

                <!-- Workshop Centers -->

                <div *ngIf="isActive('services')" class="services">
                    <div *ngFor="let service of services" class="service-target" (click)="changeServiceStatus(service.serviceId)">
                        <div class="title">
                            <span>{{ service.nameES }}</span>
                        </div>
                        <div class="ribbon-btn" *ngIf="!service.hasService">
                            <div class="ribbon">{{ 'disabled' | translate }}</div>
                        </div>
                        <div class="ribbon-btn" *ngIf="service.hasService">
                            <div class="ribbon-active">{{ 'activated' | translate }}</div>
                        </div>
                    </div>
                </div>

              <div *ngIf="isActive('timetables')" class="timetables">
                <table mat-table [dataSource]="workshopForm.get('timetableRows').controls" class="mat-elevation-z8 timetables-table">

                  <!-- Day Column -->
                  <ng-container matColumnDef="day">
                    <th mat-header-cell *matHeaderCellDef> Día </th>
                    <td mat-cell *matCellDef="let row"> {{ 'timetables.' + (row.get('day').value) | translate}} </td>
                  </ng-container>

                  <!-- Start Morning Hour Column -->
                  <ng-container matColumnDef="startMorningHour">
                    <th mat-header-cell *matHeaderCellDef> {{ 'initialHour' | translate }} </th>
                    <td mat-cell *matCellDef="let row">
                      <input [formControl]="row.get('startMorningHour')" type="time">
                    </td>
                  </ng-container>

                  <!-- End Morning Hour Column -->
                  <ng-container matColumnDef="endMorningHour">
                    <th mat-header-cell *matHeaderCellDef> {{ 'endHour' | translate }} </th>
                    <td mat-cell *matCellDef="let row">
                      <input [formControl]="row.get('endMorningHour')" type="time">
                    </td>
                  </ng-container>

                  <!-- Start Afternoon Hour Column -->
                  <ng-container matColumnDef="startAfternoonHour">
                    <th mat-header-cell *matHeaderCellDef> {{ 'initialHour' | translate }} </th>
                    <td mat-cell *matCellDef="let row">
                      <input [formControl]="row.get('startAfternoonHour')" type="time">
                    </td>
                  </ng-container>

                  <!-- End Afternoon Hour Column -->
                  <ng-container matColumnDef="endAfternoonHour">
                    <th mat-header-cell *matHeaderCellDef> {{ 'endHour' | translate }} </th>
                    <td mat-cell *matCellDef="let row">
                      <input [formControl]="row.get('endAfternoonHour')" type="time">
                    </td>
                  </ng-container>

                  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

                </table>
              </div>

            </div>
        </div>

    </form>
</div>
