import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MenuComponent } from './components/shared/menu/menu.component';
import { HeaderComponent } from './components/shared/header/header.component';
import { BrandServicesComponent } from './components/brand-services/brand-services.component';
import { BrandPromotionsComponent } from './components/brand-promotions/brand-promotions.component'
import { Routes, RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { InlineSVGModule } from 'ng-inline-svg';
import { SharingService } from './services/sharing.service';
import { ApserviceService } from './services/apservice.service';
import { NotifierModule, NotifierOptions } from 'angular-notifier';
import { FileUploadModule } from 'ng2-file-upload';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrandWorkshopsComponent } from './components/brand-workshops/brand-workshops.component';
import { BrandModifyWorkshopComponent } from './components/brand-workshops/brand-modify-workshop/brand-modify-workshop.component';
import { BrandWorkshopMapComponent } from './components/brand-workshops/brand-workshop-map/brand-workshop-map.component';
import { BrandBiComponent } from './components/brand-bi/brand-bi.component';
import { SubmenuComponent } from './components/shared/menu/submenu/submenu.component';
import { BrandConfigurationComponent } from './components/brand-configuration/brand-configuration.component';
import { CustomFormsModule } from 'ng2-validation-nor';
import { LoginComponent } from './components/login/login.component'
import { AuthGuardService } from './services/auth-guard.service';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader} from '@ngx-translate/http-loader';
import { AgmCoreModule } from '@agm/core';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { ProductRatesComponent } from './components/product-rates/product-rates.component';
import {MatTableModule} from '@angular/material/table';

const routes: Routes = [
  { path: 'configuracion', component: BrandConfigurationComponent, canActivate: [ AuthGuardService ]},
  { path: 'promociones', component: BrandPromotionsComponent, canActivate: [ AuthGuardService ]},
  { path: 'servicios', component: BrandServicesComponent, canActivate: [ AuthGuardService ]},
  { path: 'talleres', component: BrandWorkshopsComponent, canActivate: [ AuthGuardService ]},
  { path: 'product-rates', component: ProductRatesComponent, canActivate: [ AuthGuardService ]},
  { path: 'talleres/:id', component: BrandModifyWorkshopComponent, canActivate: [ AuthGuardService ]},
  { path: 'bi', component: BrandBiComponent, canActivate: [ AuthGuardService ]},
  { path: 'login', component: LoginComponent},
  // { path: 'configuracion', component: ContactComponent, canActivate: [AuthGuardService]},
  { path: '**', pathMatch: 'full', redirectTo: '/login' }
];

const options: NotifierOptions = {
  position: {
      horizontal: {
          position: 'right',
          distance: 12
      },
      vertical: {
          position: 'bottom',
          distance: 50,
          gap: 10
      }
  },
  theme: 'material',
  behaviour: {
      autoHide: 3500,
      onClick: false,
      onMouseover: 'pauseAutoHide',
      showDismissButton: true,
      stacking: 4
  },
  animations: {
      enabled: true,
      show: {
          preset: 'slide',
          speed: 300,
          easing: 'ease'
      },
      hide: {
          preset: 'fade',
          speed: 300,
          easing: 'ease',
          offset: 50
      },
      shift: {
          speed: 300,
          easing: 'ease'
      },
      overlap: 150
  }
};



@NgModule({
  declarations: [
    AppComponent,
    MenuComponent,
    HeaderComponent,
    BrandServicesComponent,
    BrandPromotionsComponent,
    BrandWorkshopsComponent,
    BrandModifyWorkshopComponent,
    BrandWorkshopMapComponent,
    BrandServicesComponent,
    ProductRatesComponent,
    BrandBiComponent,
    SubmenuComponent,
    BrandConfigurationComponent,
    LoginComponent,
  ],
  imports: [
    HttpClientModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    RouterModule.forRoot(routes, {onSameUrlNavigation: 'ignore' }),
    RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled'}),
    RouterModule.forRoot(routes, { useHash: true }),
    FontAwesomeModule,
    FileUploadModule,
    AngularEditorModule,
    FormsModule,
    InlineSVGModule.forRoot(),
    AgmCoreModule.forRoot(),
    NotifierModule.withConfig(options),
    NgxDatatableModule,
    CustomFormsModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyA5qATUwcOpUwRNFcADPQAqi_NNF2Qhzi4',
      language: 'es',
      libraries: ['geometry', 'places'],
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    MatTableModule
  ],
  providers: [SharingService, ApserviceService, AuthGuardService],
  bootstrap: [AppComponent]
})
export class AppModule { }

export function createTranslateLoader(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}
