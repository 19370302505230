import { Component, OnInit, ChangeDetectorRef, AfterContentChecked, AfterViewChecked } from '@angular/core';
import { Location } from '@angular/common';
import { SharingService } from 'src/app/services/sharing.service';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  faArrowLeft = faArrowLeft;

  constructor(
    public _ss: SharingService,
    private location: Location,
    private cdr: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
  }

  onSave(){
    this._ss.saveEvent();
  }

  onBack(){
    this.location.back();
  }

  onCreate(){
    this._ss.createEvent();
  }

  onSaveExit(){
    this._ss.saveExitEvent();
  }

  onSaveNew(){
    this._ss.saveNewEvent();
  }

  onPrint(){
    this._ss.printEvent();
  }

}
